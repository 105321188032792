import React from 'react';

const ShippingPolicy = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Shipping Policy</h1>
      <p className="mb-6">
        At Oakland Furniture, we are committed to delivering high-quality furniture to your home quickly and reliably. 
        Please read our shipping policy for important information regarding shipping rates, delivery times, and order processing.
      </p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Domestic Shipping</h2>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Shipping Rates and Delivery Times</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Standard Shipping:</strong>
              <ul className="pl-6 space-y-1">
                <li><strong>Cost:</strong> Free for orders over $500; $49 for orders below $500.</li>
                <li><strong>Delivery Time:</strong> 5-7 business days.</li>
              </ul>
            </li>
            <li>
              <strong>Express Shipping:</strong>
              <ul className="pl-6 space-y-1">
                <li><strong>Cost:</strong> $99</li>
                <li><strong>Delivery Time:</strong> 2-3 business days.</li>
              </ul>
            </li>
          </ul>
          <p className="mt-4">Delivery times are estimates and may vary depending on your location and availability of items.</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Order Processing</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>Orders are processed within 1-2 business days. Processing does not occur on weekends or holidays.</li>
            <li>You'll receive an order confirmation email upon placing your order, and another email with tracking details once your items have shipped.</li>
          </ul>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Tracking Your Order</h3>
          <p>
            Once your furniture has been shipped, you will receive a tracking number via email. 
            You can track your shipment on the carrier's website or through our 
            <a href="#" className="text-blue-600 ml-1">Order Tracking Page</a>.
          </p>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-2">Shipping Restrictions</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>We currently ship to physical addresses only; we do not deliver to PO Boxes.</li>
            <li>Delivery to remote or rural areas may take additional time.</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Delays and Issues</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li><strong>Weather Delays:</strong> Severe weather conditions may affect shipping times. We will keep you informed of any major delays.</li>
          <li>
            <strong>Lost or Damaged Shipments:</strong> Oakland Furniture is not liable for lost or damaged shipments. 
            If an issue arises, please contact the shipping carrier with your tracking information for assistance.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Changes to Your Order</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            <strong>Address Changes:</strong> If you need to update your shipping address, contact us immediately. 
            Changes cannot be made after the order has shipped.
          </li>
          <li>
            <strong>Order Cancellations:</strong> To cancel your order, contact us within 1-2 hours of placing it. 
            Once processing begins, cancellations may not be possible.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about your order or our shipping policy, reach out to us:
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            <strong>Email:</strong>{' '}
            <a href="mailto:support@oaklandfurniture.com" className="text-blue-600">support@oaklandfurniture.com</a>
          </li>
          <li>
            <strong>Phone:</strong> +447727914602
          </li>
        </ul>
        <p className="mt-4">
          <strong>Hours:</strong> Monday to Friday, 9:00 AM - 6:00 PM
        </p>
        <p className="mt-6">
          Thank you for choosing Oakland Furniture. We appreciate your business and are dedicated to providing you with exceptional service and quality products!
        </p>
      </section>
    </div>
  );
};

export default ShippingPolicy;
