import React, { useState } from "react";

export default function SizeSelector({ sizes, selectedSize, setSelectedSize }) {
  const [selectedIndex,setSelectedIndex]=useState(0)
  return (
    <div className="productDetailPage__description">
      <h1>Select Size:</h1>
      <div className="py-5 gap-5 flex flex-wrap">
        {sizes?.map((sizeOption, index) => (
          <label key={index} className={`w-48  border cursor-pointer  ${selectedSize === sizeOption.size?'border border-black text-black':'border-gray-300'} py-4  rounded-lg px-10`}>
            <input
              className="hidden"
              type="radio"
              name="size"
              value={sizeOption.size}
              checked={selectedSize === sizeOption.size}
              onChange={() => setSelectedSize(sizeOption)}
            />
            <span className="sizeSelector__label">
              {sizeOption.size} 
            </span>
          </label>
        ))}
      </div>
      {sizes &&  sizes[selectedIndex]?.measurements.length!==0?<h2 className="py-5 space-x-5 text-lg font-medium">
        Measuments
      </h2>:<></>}
      <div className="space-y-3 lg:w-2/3 w-full">
        {
        sizes&&  sizes[selectedIndex]?.measurements?.map((val,index)=>(

            <div key={index} className="flex justify-between border-b border-gray-400">
            <p>
              {val?.name}
            </p>
            <p>
              {val?.value} cm
            </p>
          </div>
          ))
        }
      </div>
    </div>
  );
}
