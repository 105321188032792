import React from 'react';

const ContactInformation = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Contact Information</h1>
      <p className="mb-4">
        At Oakland Furniture, we prioritize your satisfaction and are here to assist with any inquiries or concerns. 
        Please use the contact information below to reach us. We look forward to helping you find the perfect furniture for your space!
      </p>

      <h2 className="text-2xl font-semibold mb-2">Customer Service</h2>
      <ul className="list-disc pl-5 mb-4">
        <li>
          <strong>Email:</strong>{' '}
          <a href="mailto:support@oaklandfurniture.com" className="text-blue-600">
            support@oaklandfurniture.com
          </a>
        </li>
        <li>
          <strong>Phone:</strong> +447727914602
        </li>
        <li>
          <strong>Available:</strong> Monday to Saturday (9:00 AM to 7:00 PM)
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Showroom Location</h2>
      <p className="mb-4">
        Visit our showroom to explore our range of premium furniture in person:
      </p>
      <p className="mb-4">
        <strong>Address:</strong> 1234 Oak Street, Oakland, CA 94612
      </p>
      <p>
        <strong>Hours:</strong> Monday to Saturday, 9:00 AM - 7:00 PM
      </p>

      <h2 className="text-2xl font-semibold mb-2">Social Media</h2>
      <p className="mb-4">Follow us on social media for updates, promotions, and design inspiration:</p>
      <ul className="list-disc pl-5 mb-4">
        <li>
          <strong>Facebook:</strong>{' '}
          <a href="https://www.facebook.com/oaklandfurniture" className="text-blue-600">
            Oakland Furniture
          </a>
        </li>
        <li>
          <strong>Instagram:</strong>{' '}
          <a href="https://www.instagram.com/oaklandfurniture" className="text-blue-600">
            @oaklandfurniture
          </a>
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Frequently Asked Questions (FAQ)</h2>
      <p className="mb-4">
        For quick answers to common questions about our products, delivery options, or policies, visit our{' '}
        <a href="#" className="text-blue-600">
          FAQ page
        </a>.
      </p>

      <p className="mt-4">
        Thank you for choosing Oakland Furniture. We are committed to offering you exceptional service and high-quality furniture to elevate your living space.
      </p>
    </div>
  );
};

export default ContactInformation;
