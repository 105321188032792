import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p>
        At Oakland Furniture, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website or make a purchase.
      </p>

      <h2 className="text-xl font-bold py-5">Information We Collect</h2>
      <h3>Personal Information</h3>
      <p>
        When you make a purchase or register on our site, we may collect the following personal information:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Shipping and billing address</li>
        <li>Phone number</li>
        <li>Payment information (e.g., credit card details)</li>
      </ul>

      <h3>Non-Personal Information</h3>
      <p>
        We also collect non-personal information to improve your shopping experience:
      </p>
      <ul>
        <li>Browser type and version</li>
        <li>Operating system</li>
        <li>IP address</li>
        <li>Website usage data</li>
      </ul>

      <h2 className="text-xl font-bold py-5">How We Use Your Information</h2>
      <p>
        We use your personal information for various purposes, including:
      </p>
      <ul>
        <li>Order Fulfillment: To process your furniture orders and manage your account.</li>
        <li>Customer Support: To assist you with inquiries, orders, and after-sales services.</li>
        <li>Promotions: To send you details about special offers, new collections, and other relevant updates. You can opt out of receiving these communications at any time.</li>
        <li>Service Improvement: To analyze website usage and enhance your shopping experience with improved features and product offerings.</li>
      </ul>

      <h2 className="text-xl font-bold py-5">Sharing Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with trusted third parties who assist us in operating our website, delivering furniture, or processing payments. These parties are bound to keep your information confidential.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your information when required to comply with legal obligations, enforce our site policies, or protect the rights, property, or safety of our customers and business.
        </li>
      </ul>

      <h2 className="text-xl font-bold py-5">Protecting Your Information</h2>
      <p>
        We take the security of your personal information seriously and implement measures such as:
      </p>
      <ul>
        <li>SSL Encryption: Secure Socket Layer (SSL) encryption safeguards data during transmission.</li>
        <li>Secure Storage: Sensitive information is stored securely using data encryption techniques.</li>
        <li>Access Control: Only authorized personnel can access your personal information for specific purposes.</li>
      </ul>

      <h2 className="text-xl font-bold py-5">Your Choices</h2>
      <ul>
        <li>
          <strong>Update Information:</strong> You can review and update your personal details by logging into your account on our website.
        </li>
        <li>
          <strong>Opt Out:</strong> Unsubscribe from marketing emails using the link provided in each email or contact us for assistance.
        </li>
      </ul>

      <h2 className="text-xl font-bold py-5">Cookies and Tracking Technologies</h2>
      <p>
        We use cookies to enhance your browsing experience on our website:
      </p>
      <ul>
        <li>
          <strong>Cookies:</strong> Small files stored on your device that help us remember your preferences and improve website functionality.
        </li>
        <li>
          <strong>Analytics:</strong> Third-party services may monitor website usage to help us improve our offerings.
        </li>
      </ul>

      <h2 className="text-xl font-bold py-5">Changes to Our Privacy Policy</h2>
      <p>
        We reserve the right to update this Privacy Policy. If significant changes are made, we will notify you via email or by posting a notice on our website. Continuing to use our website after such updates signifies your acceptance of the revised policy.
      </p>

      <h2 className="text-xl font-bold py-5">Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at:
        <br />
        Instagram & Facebook: oaklandfurniture
        <br />
        Email: support@oaklandfurniture.com
        <br />
        Phone: +447727914602
        <br />
        Monday to Friday (9:00 AM to 6:00 PM)
      </p>

      <p>
        Thank you for choosing Oakland Furniture. We are dedicated to protecting your privacy and providing you with a secure and enjoyable shopping experience.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
