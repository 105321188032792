import React from 'react'; // Import your CSS file for styling

const TermsOfService = () => {
  return (
    <div className="legal">
      <h1>Terms of Service</h1>
      <p>
        Welcome to Oakland Furnitures! These Terms of Service govern your use of our website and the purchase of our products. By accessing or using our website, you agree to be bound by these terms. If you do not agree with any part of these terms, please do not use our website.
      </p>

      <h2>General Conditions</h2>
      <ol>
        <li>
          <strong>Acceptance of Terms:</strong> By accessing and using our website, you agree to comply with and be bound by these Terms of Service and all applicable laws and regulations.
        </li>
        <li>
          <strong>Modification of Terms:</strong> We reserve the right to modify these terms at any time. Any changes will be posted on this page. Your continued use of the website after such modifications constitutes your acceptance of the new terms.
        </li>
      </ol>

      <h2>Use of Our Website</h2>
      <ol>
        <li>
          <strong>Account Creation:</strong> When you create an account on our website, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.
        </li>
        <li>
          <strong>Prohibited Activities:</strong> You agree not to use our website for any unlawful purpose or engage in any conduct that could damage, disable, overburden, or impair the website.
        </li>
      </ol>

      <h2>Orders and Payments</h2>
      <ol>
        <li>
          <strong>Order Acceptance:</strong> All orders placed through our website are subject to our acceptance. We may, in our sole discretion, refuse or cancel any order for any reason.
        </li>
        <li>
          <strong>Payment:</strong> You agree to provide current, complete, and accurate purchase and account information for all purchases made through our website. You agree to promptly update your account and other information, including your email address and payment method, so that we can complete your transactions and contact you as needed.
        </li>
        <li>
          <strong>Pricing:</strong> All prices are subject to change without notice. We reserve the right to modify or discontinue products at any time.
        </li>
      </ol>

      <h2>Shipping and Delivery</h2>
      <ol>
        <li>
          <strong>Shipping Policy:</strong> Please review our <a href="/shipping-policy">Shipping Policy</a> for detailed information about our shipping practices and delivery times.
        </li>
        <li>
          <strong>Risk of Loss:</strong> All items purchased from our website are made pursuant to a shipment contract. This means that the risk of loss and title for such items pass to you upon our delivery to the carrier.
        </li>
      </ol>

      <h2>Returns and Refunds</h2>
      <ol>
        <li>
          <strong>Return Policy:</strong> Please review our <a href="/return-policy">Return Policy</a> for information on how to return furniture and receive a refund.
        </li>
        <li>
          <strong>Non-Returnable Items:</strong> Certain items, such as custom-built furniture or clearance sale products, are not eligible for return.
        </li>
      </ol>

      <h2>Intellectual Property</h2>
      <ol>
        <li>
          <strong>Ownership:</strong> All content on our website, including text, graphics, logos, images, and software, is the property of Oakland Furnitures or its content suppliers and is protected by international copyright laws.
        </li>
        <li>
          <strong>License:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our website for personal, non-commercial use. Any unauthorized use of our website or its content is prohibited.
        </li>
      </ol>

      <h2>Limitation of Liability</h2>
      <ol>
        <li>
          <strong>No Warranties:</strong> Our website and all content, products, and services provided through it are provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.
        </li>
        <li>
          <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, Oakland Furnitures disclaims all liability for any damages arising out of or in connection with your use of our website or the purchase of products through our website. This includes, but is not limited to, direct, indirect, incidental, punitive, and consequential damages.
        </li>
      </ol>

      <h2>Governing Law</h2>
      <p>
        These Terms of Service and any separate agreements whereby we provide you products or services shall be governed by and construed in accordance with the laws of your country or state.
      </p>

      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms of Service, please contact us at:</p>
      <p>Facebook & Instagram: oaklandfurnitures</p>
      <p>Email: support@oaklandfurnitures.com</p>
      <p>Monday to Saturday (10:00 am to 06:00 pm)</p>

      <p>Thank you for choosing Oakland Furnitures. We value your trust and look forward to serving you!</p>
    </div>
  );
};

export default TermsOfService;
