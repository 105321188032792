import React from "react";
import NavigationText from "./NavigationText";
import BrandIcon from "./BrandIcon";
import { useNavigate } from "react-router-dom";
import Burger from "../../../assests/burger.svg"
export default function HeaderLeftMenu() {
  const navigate = useNavigate();

  function navigateHandler(url) {
    window.scrollTo(0, 0);
    navigate(url);
  }

  return (
    <div className="header__container lg:px-12 px-6 py-4 ">
      <div className="drawer lg:hidden block">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          {/* Page content here */}
          <label htmlFor="my-drawer" className="drawer-button"><img src={Burger} alt="icon" className="w-8" /></label>
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
          <ul className="menu bg-white !text-4xl min-h-full w-80 p-12">
            {/* Sidebar content here */}
            <BrandIcon onClick={() => navigateHandler("/")} />
            <NavigationText text={"Home"} url="/" />
            <NavigationText text={"New Arrivals"} url="/newarrivals" />
            <NavigationText text={"Sofa"} url="/shop?query=Sofa" />
            <NavigationText text={"Wardrobe"} url="/shop?query=Wardrobe" />
            <NavigationText text={"All Products"} url="/shop" />
          </ul>
        </div>
      </div>
      <BrandIcon onClick={() => navigateHandler("/")} />

      <div className="lg:flex hidden w-full pl-12 ">
        <NavigationText text={"Home"} url="/" />
        <NavigationText text={"New Arrivals"} url="/newarrivals" />
        <NavigationText text={"Sofa"} url="/shop?query=Sofa" />
            <NavigationText text={"Wardrobe"} url="/shop?query=Wardrobe" />
            <NavigationText text={"All Products"} url="/shop" />
      </div>
    </div>
  );
}
