import React from "react";
import { useNavigate } from "react-router-dom";
import Insta from "../../assests/insta.svg"
import Fb from "../../assests/fb.svg"

export default function Footer() {
  const navigate = useNavigate();
  return (
    <ul className="flex lg:flex-row flex-col lg:space-y-0 space-y-4  py-4 mt-12 lg:justify-end justify-start lg:px-12 px-0 lg:space-x-10 space-x-0 items-center">
      <a href="https://www.instagram.com/furnitureoakland?igsh=eHg2NWVpcTByazhn"><img src={Insta} alt="some" className="w-10"/></a>
      <a href="https://www.facebook.com/profile.php?id=61565357161590&mibextid=JRoKGi"><img src={Fb} alt="some" className="w-10"/></a>
      <li className="cursor-pointer" onClick={() => navigate("/legal")}>Terms of use</li>
      <li className="cursor-pointer" onClick={() => navigate("/privacy")}>Privacy</li>
      <li className="cursor-pointer" onClick={() => navigate("/shipping-policy")}>Shipping Policy</li>
      <li className="cursor-pointer" onClick={() => navigate("/contact")}>Contact</li>
      <li>Copyright © Oakland Furnitures</li>
    </ul>
  );
}
