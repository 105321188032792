// ColorSelector.js
import React from "react";

export default function ColorSelector({ colors, selectedColor, setSelectedColor }) {
  return (
    <div className="my-4">
      <h2 className="text-lg font-semibold mb-2">Select Color:</h2>
      <div className="flex gap-3">
        {colors.map((color, index) => (
          <div
            key={index}
            className={`w-12 h-12 rounded-full cursor-pointer border-2 
              transition-transform duration-300 ease-in-out
              ${selectedColor.colorName === color.colorName ? "border-gray-800 shadow-lg scale-110" : "border-transparent"}`}
            style={{ backgroundColor: color.colorName }}
            onClick={() => setSelectedColor(color)}
          />
        ))}
      </div>
    </div>
  );
}
